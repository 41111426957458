import fetch from '../utility/fetcher'

export type LianlianPayInfo = {
  pay_code: string
  order_total: string
  lian_trade_no: string
  wxpay_trade_no: string
  order_type: 'pc_recharge' | 'c_order_pay'
}

/**
 * 根据连连单号获取连连支付信息
 */
export function getLianlianPayInfo(subChnlNo: string) {
  return fetch<LianlianPayInfo>({
    Url: 'LianlianPay.QueryOrder',
    SubChnlNo: subChnlNo,
  })
}

/**
 * 获取银行列表
 */
export function getBankList() {
  return fetch<Array<{ id: string; bank_name: string }>>({
    Url: 'LianlianPay.BankList',
    Key: localStorage.getItem('c_token') || '',
  })
}

type BindBankCardParams = {
  /**
   * 银行卡号
   */
  acctno: string
  /**
   * 银行ID
   */
  bankId: string
  /**
   * 持卡人姓名
   */
  acctname: string
  /**
   * 身份证号
   */
  id: string
  /**
   * 预留手机号
   */
  phone: string
}

export type BindBankCardData = {
  apply_token: string
  apply_trade_no: string
}

/**
 * 绑定银行卡
 */
export function bindBankCard(params: BindBankCardParams) {
  return fetch<BindBankCardData>({
    Url: 'LianlianPay.BindPayCard',
    Key: localStorage.getItem('c_token') || '',
    Acctno: params.acctno,
    BankID: params.bankId,
    Acctname: params.acctname,
    ID: params.id,
    Phone: params.phone,
  })
}

type BindBankCardVerifyParams = {
  applyToken: string
  applyTradeNo: string
  verifyCode: string
}

/**
 * 绑定银行卡确认
 */
export function bindBankCardVerify(params: BindBankCardVerifyParams) {
  return fetch({
    Url: 'LianlianPay.BindPayCardVerify',
    Key: localStorage.getItem('c_token') || '',
    ApplyToken: params.applyToken,
    ApplyTradeNo: params.applyTradeNo,
    VerifyCode: params.verifyCode,
  })
}

/**
 * 获取我的银行卡列表
 */
export function getBankCardList() {
  return fetch<{ list: Array<any> }>({
    Url: 'LianlianPay.BankCardPayList',
    Key: localStorage.getItem('c_token') || '',
  })
}

/**
 * 设置默认银行卡
 */
export function setDefaultBankCard(agrtno: string) {
  return fetch({
    Url: 'LianlianPay.SetDefaultCard',
    Key: localStorage.getItem('c_token') || '',
    Agrtno: agrtno,
  })
}

/**
 * 解绑银行卡
 */
export function unbindBankCard(agrtno: string) {
  return fetch({
    Url: 'LianlianPay.UnlinkPayCard',
    Key: localStorage.getItem('c_token') || '',
    Agrtno: agrtno,
  })
}
