import fetch from '../utility/fetcher'

/**
 * 获取微信支付信息
 */
export function getWechatPaymentInfo() {
  return fetch({
    Url: 'Pay.Wxpay',
    Key: localStorage.getItem('c_token'),
    Paycode: localStorage.getItem('pay_code'),
  })
}

/**
 * 获取连连支付信息
 * @param payChannel 4 H5 / 2 支付宝 / 8 银行卡
 */
export function getLianlianPaymentInfo(payChannel: 4 | 2 | 8) {
  return fetch({
    Url: 'LianlianPay.Pay',
    Key: localStorage.getItem('c_token'),
    Paycode: localStorage.getItem('pay_code'),
    PayChannel: payChannel,
    // 连连支付完成回调地址
    ReturnUrl: `${window.location.protocol}//${window.location.host}/order`,
  })
}

/**
 * 支付宝支付状态查询
 * @param payCode 支付单号
 */
export function queryAlipayOrderStatus(payCode: string) {
  return fetch({
    Url: 'LianlianPay.QueryOrder',
    Key: localStorage.getItem('c_token'),
    PayCode: payCode,
  })
}
