import { useCallback, useState } from 'react'
import { Toast } from 'antd-mobile'

/**
 * 复制文本到剪贴板
 * @description 暂时不用 navigator.clipboard.writeText(text) 方法，因为在 iOS 上有限制。
 */
export function useCopyText() {
  const [copyState, setCopyState] = useState(false)

  const copyText = useCallback(
    (text: string, successText: string = '复制成功') => {
      try {
        const input = document.createElement('input')
        input.style.position = 'fixed'
        input.style.left = '-10000px'
        input.style.top = '-10000px'
        input.readOnly = true
        input.value = text
        document.body.appendChild(input)
        input.focus()
        input.setSelectionRange(0, 999999)
        document.execCommand('copy')
        document.body.removeChild(input)
        setCopyState(true)
        Toast.info(successText, 1.5, undefined, false)
      } catch (e) {
        Toast.info('复制失败，请重试', 1.5, undefined, false)
      }
    },
    []
  )

  return [copyText, copyState] as const
}
