import { useEffect } from 'react'

import { uploadDeviceInfo } from '../api'
import type { ShopInfoProps } from '../components'
import { useRequest, useShopId, useToken } from '../hooks'

export function useShopInfo(enabled = true) {
  const [key] = useToken()
  const { shopId, branchId } = useShopId()
  const res = useRequest<ShopInfoProps>(
    enabled
      ? {
          Url: 'Shop.ShopInfo',
          Key: key,
          ShopID: shopId,
          BranchID: branchId,
        }
      : null
  )

  useEffect(() => {
    if (res.data) {
      const { is_login, ip, shop_id } = res.data

      // 登录状态下，店铺id或ip变动时上报设备信息
      if (
        is_login === 1 &&
        [ip, shop_id].join('|') !== localStorage.getItem('ip')
      ) {
        uploadDeviceInfo(() => {
          // 上报成功再存储ip，防止失败后不再上报
          localStorage.setItem('ip', [ip, shop_id].join('|'))
        })
      }
    }
  }, [res.data])

  return res
}
