import aesjs from 'aes-js'
import base64js from 'base64-js'
import md5 from 'md5'

const KEY = process.env.REACT_APP_AES_KEY || ''
const IV = process.env.REACT_APP_AES_IV || ''

/**
 * AES加密（AES-128-CBC）
 * @see 参考 https://github.com/pkuoliver/EasyAES
 */
export class AES {
  private static instance: Readonly<AES> | null = null
  private readonly key: number[]
  private readonly iv: number[]

  private constructor(key: string, iv: string) {
    if (!key || !iv) {
      throw new Error('Key and IV must not be empty')
    }

    this.key = md5(key, { asBytes: true }) // 注意 asBytes
    this.iv = md5(iv, { asBytes: true }) // 注意 asBytes
  }

  public static getInstance(key: string, iv: string): Readonly<AES> {
    if (!AES.instance) {
      AES.instance = Object.freeze(new AES(key, iv))
    }
    return AES.instance
  }

  /**
   * 加密
   */
  encrypt(text: string) {
    const aesCbc = new aesjs.ModeOfOperation.cbc(this.key, this.iv)
    const textBytes = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(text))
    const encryptedBytes = aesCbc.encrypt(textBytes)
    const encryptedText = base64js.fromByteArray(encryptedBytes)
    return encryptedText
  }

  /**
   * 解密
   */
  decrypt(text: string) {
    const aesCbc = new aesjs.ModeOfOperation.cbc(this.key, this.iv)
    const encryptedBytes = base64js.toByteArray(text)
    const decryptedBytes = aesCbc.decrypt(encryptedBytes)
    const decryptedText = aesjs.utils.utf8.fromBytes(
      aesjs.padding.pkcs7.strip(decryptedBytes)
    )
    return decryptedText
  }
}

// 单例
export const aes = AES.getInstance(KEY, IV)
