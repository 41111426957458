import fingerprintjs, { GetResult } from '@fingerprintjs/fingerprintjs'

// 暂时去掉一些数据量太大的key
const INCLUDED_KEYS = [
  'languages',
  'deviceMemory',
  'screenResolution',
  'timezone',
  'platform',
  'architecture',
  'webGlBasics',
]

/**
 * 获取设备指纹
 */
export async function getFingerprint() {
  const fpPromise = await fingerprintjs.load()
  const result = await fpPromise.get()

  return {
    visitorId: result.visitorId,
    components: getComponents(result.components),
  }
}

function getComponents(components: GetResult['components']) {
  return Object.fromEntries(
    Object.entries(components)
      .filter(([key]) => INCLUDED_KEYS.includes(key))
      .map(([key, valueObj]: [string, any]) => [
        key,
        valueObj.value || 'unknown',
      ])
  )
}
