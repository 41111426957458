import fetch from '../utility/fetcher'

export type Address = {
  id: number
  name: string
}

export type AutoAddressData = {
  province: Address
  city: Address
  area: Address
  name: string
  mobile: string
  address: any
}

/**
 * 自动识别地址
 */
export function autoAddress(Address: string) {
  return fetch<AutoAddressData>({
    Url: 'Buy.AutoAddress',
    Address,
    Key: localStorage.getItem('c_token'),
  })
}

type Payment = {
  id: string
  code: string
  logo: string
  name: string
}

/**
 * 获取支付方式列表
 */
export function getPaymentList() {
  return fetch<Array<Payment>>({
    Url: 'Buy.PaymentList',
    Key: localStorage.getItem('c_token'),
    platform: 3,
  })
}
