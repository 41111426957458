import React, { useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import { Icon, Toast } from 'antd-mobile'
import useSWR from 'swr'
import QRCode from 'qrcode'

import { ShowSimpleContent } from '../../utility/modal'
import { useWechatHideAllNonBaseMenuItem } from '../../contexts/wechat'
import { useCopyText } from '../../hooks'
import { getPaymentList } from '../../api'
import {
  getLianlianPaymentInfo,
  getWechatPaymentInfo,
  queryAlipayOrderStatus,
} from '../../api/pay'
import { isIOS } from '../../utility/utils'

const paymentApiMap = {
  'jpd-c': getWechatPaymentInfo,
  'lianlian-wxpay-h5': getLianlianPaymentInfo.bind(null, 4),
  'lian-alipay-c-h5': getLianlianPaymentInfo.bind(null, 2),
  'lian-debit-c-h5': getLianlianPaymentInfo.bind(null, 8),
}

const Pay = (props) => {
  const [paymentCode, setPaymentCode] = useState('')
  const { data: paymentList = [] } = useSWR(
    'Buy.PaymentList',
    () => getPaymentList(),
    { revalidateOnMount: true }
  )

  const [alipayInfo, setAlipayInfo] = useState(null)
  const [qrcodeVisible, setQrcodeVisible] = React.useState(false)
  const [qrcodeUrl, setQrcodeUrl] = React.useState('')
  const [copyText] = useCopyText()

  async function handlePay() {
    if (paymentList.length === 0) {
      return
    }

    Toast.loading('支付中...', 0)

    try {
      const data = await paymentApiMap[paymentCode]()

      if (paymentCode === 'jpd-c' || paymentCode === 'lianlian-wxpay-h5') {
        function onBridgeReady() {
          let payload = {}

          if (paymentCode === 'jpd-c') {
            payload = data
          } else if (paymentCode === 'lianlian-wxpay-h5') {
            payload = JSON.parse(data.payload)
          }

          Toast.hide()
          window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            { ...payload, timeStamp: payload.timeStamp || payload.timestamp },
            function (res) {
              if (res.err_msg === 'get_brand_wcpay_request:ok') {
                Toast.success('支付成功', 1, () =>
                  props.history.replace('/order')
                )
              } else {
                Toast.fail('支付取消', 1, () => props.history.replace('/order'))
              }
            }
          )
        }

        if (typeof WeixinJSBridge === 'undefined') {
          if (document.addEventListener) {
            document.addEventListener(
              'WeixinJSBridgeReady',
              onBridgeReady,
              false
            )
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
          }
        } else {
          onBridgeReady()
        }
      } else if (paymentCode === 'lian-alipay-c-h5') {
        setAlipayInfo(data)
        QRCode.toDataURL(data.gateway_url, { margin: 0 }).then((url) => {
          setQrcodeUrl(url)
          setQrcodeVisible(true)
          Toast.hide()

          // 有吱口令则复制吱口令，没有则复制支付链接
          if (!isIOS) {
            copyText(data.zhi_token || data.gateway_url, '内容已复制')
          }
        })
      } else {
        // TODO: 银行卡支付
        Toast.hide()
        Toast.info('暂不支持')
      }
    } catch (error) {
      Toast.hide()
      Toast.fail(error.msg)
    }
  }

  function copyAlipayText() {
    copyText(alipayInfo.zhi_token || alipayInfo.gateway_url, '内容已复制')
  }

  useWechatHideAllNonBaseMenuItem()

  // 初始化支付方式
  useEffect(() => {
    if (!paymentCode && paymentList) {
      setPaymentCode(paymentList[0]?.code)
    }
  }, [paymentCode, paymentList])

  // 监听支付状态
  useInterval(
    async () => {
      try {
        await queryAlipayOrderStatus(alipayInfo.pay_code)
        Toast.success('支付成功', 1, () => props.history.replace('/order'))
      } catch (error) {
        console.log(error.msg || error.message)
      }
    },
    alipayInfo && qrcodeVisible ? 3000 : null
  )

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: '#F4F4F4',
        padding: '50px 10px 10px 10px',
        position: 'relative',
      }}
    >
      <div
        style={{
          marginBottom: 30,
          marginTop: 30,
          color: '#FF4F64',
          textAlign: 'center',
          fontSize: 22,
        }}
      >
        <span>￥</span>
        <span style={{ fontSize: 28 }}>
          {localStorage.getItem('pay_money')}
        </span>
      </div>

      {paymentList.map((item) => {
        return (
          <div
            key={item.code}
            style={{
              padding: 2,
              borderBottom: '1px solid #E7E7E7',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onClick={() => setPaymentCode(item.code)}
          >
            <div style={{ display: 'flex', padding: 5 }}>
              <img
                style={{
                  width: 45,
                  height: 45,
                  padding: 5,
                  objectFit: 'contain',
                }}
                src={item.logo}
                alt=""
              />
              <p style={{ alignSelf: 'center' }}>{item.name}</p>
            </div>

            {item.code === paymentCode ? (
              <Icon type="check-circle" color="var(--color-primary)" />
            ) : (
              <span
                style={{
                  width: 20,
                  height: 20,
                  border: '1px solid #cdcaca',
                  borderRadius: '50%',
                }}
              ></span>
            )}
          </div>
        )
      })}

      <button
        style={{
          position: 'fixed',
          height: 45,
          bottom: `calc(10px + var(--safe-area-inset-bottom))`,
          width: '90%',
          left: '5%',
          background: '#FF6D8C',
          borderRadius: 30,
          zIndex: 33,
          color: '#fff',
          fontSize: 18,
        }}
        onClick={handlePay}
      >
        立即支付
      </button>

      <ShowSimpleContent
        visible={qrcodeVisible}
        animated={false}
        handleCancel={() => setQrcodeVisible(false)}
        formContent={
          <>
            <img src={qrcodeUrl} style={{ width: 220 }} alt="" />
            <div style={{ marginTop: 12, textAlign: 'left' }}>
              <div>
                1、保存此二维码，打开
                <span style={{ color: '#FF6D8C' }}>支付宝扫一扫</span>进行支付；
              </div>
              <div>
                2、或者直接打开支付宝粘贴并搜索完成支付
                {isIOS && (
                  <>
                    （若链接未自动复制，可手动
                    <span
                      style={{ color: '#3488d6', whiteSpace: 'nowrap' }}
                      onClick={copyAlipayText}
                    >
                      点击复制
                    </span>
                    ）
                  </>
                )}
                。
              </div>
            </div>
          </>
        }
      />
    </div>
  )
}

export default Pay
